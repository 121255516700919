import React, { useEffect, useState } from "react"

import { Dialog, Transition } from "@headlessui/react"
import { graphql } from "gatsby"
import { Fragment } from "react"
import { AiFillCloseCircle } from "react-icons/ai"
import { BiFilterAlt } from "react-icons/bi"
import ComboSearch from "../components/ComboSearch"
import ElectronicCard from "../components/ElectronicCard"
import ElectronicsCollection from "../components/ElectronicsCollection"
import Filter from "../components/filter"
import Layout from "../components/layout"

const ElectronicsRoot = ({
  pageContext,
  data: {
    electronicPage,
    allElectronics,
    allBoards,
    allMotherboard,
    allWiring,
    allAccessory,
    allAccess,
    allDisplay,
    allTFT,
    allLCD,
    allPulsantiere,
    allPower,
    allApps,
    allSafetyDevice,
    filters,
    miscString,
    searchEle,
  },
}) => {
  const [searchResult, setSearchResult] = useState()
  const [activeTile, setActiveTile] = useState()
  const [subTile, setSubTile] = useState()
  const [displayValue, setDisplayValue] = useState()
  let [open, setOpen] = useState(false)
  var selectedTile = {}
  const handleActiveTile = filter => {
    setActiveTile(filter)
    setSubTile()
    setSearchResult(null)
  }
  const handleSubTile = filter => {
    setSearchResult(null)
    setSubTile(filter)
  }

  const getCollections = () => {
    if (subTile === undefined) {
      selectedTile = activeTile
    } else {
      selectedTile = subTile
    }
    switch (selectedTile?.value) {
      case undefined:
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={electronicPage.headerTitle}
            collection={allElectronics}
          />
        )

      case miscString.nodes[0].all:
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={electronicPage.headerTitle}
            collection={allElectronics}
          />
        )
      case "boards":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allBoards}
          />
        )
      case "display":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allDisplay}
          />
        )
      case "safety device":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allSafetyDevice}
          />
        )
      case "app & software":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allApps}
          />
        )
      case "safety device":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allSafetyDevice}
          />
        )
      case "operating panels":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allPulsantiere}
          />
        )
      case "app & software":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allApps}
          />
        )
      case "power boards":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allPower}
          />
        )
      case "motherboards":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allMotherboard}
          />
        )
      case "accessory boards":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allAccessory}
          />
        )
      case "wiring":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allWiring}
          />
        )
      case "access":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allAccess}
          />
        )
      case "lcd":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allLCD}
          />
        )
      case "tft":
        return (
          <ElectronicsCollection
            resultString={electronicPage.singleString[0].text}
            title={selectedTile.title}
            collection={allTFT}
          />
        )
    }
  }
  const getHeading = () => {
    if (searchResult) {
      return searchResult.categoryElectronic.title
    } else {
      if (subTile?.title) {
        return subTile?.title
      } else {
        if (activeTile?.title) {
          return activeTile?.title
        } else {
          return allElectronics.nodes[0].category.title
        }
      }
    }
  }
  useEffect(() => {
    if (searchResult) {
      setDisplayValue(true)
      setSubTile()
      setActiveTile(null)
    } else {
      if (activeTile || subTile) {
        setDisplayValue(false)
      }
    }
  }, [activeTile, subTile, searchResult])
  return (
    <Layout
      pageData={pageContext}
      seoTitle={electronicPage.seo?.seoTitle}
      seoDescription={electronicPage.seo?.seoDescription}
      seoImage={electronicPage.seo?.image?.seoImageUrl}
    >
      <div className="relative mx-auto mt-2 flex w-full max-w-screen-xl items-start justify-start gap-4 px-4">
        <aside className="sticky top-0 left-0 mt-2 hidden h-fit w-fit flex-col items-start justify-start  md:flex">
          <Filter
            allString={miscString.nodes[0].all}
            filters={filters}
            activeTile={activeTile}
            subTile={subTile}
            handleActiveTile={handleActiveTile}
            handleSubTile={handleSubTile}
            selectedTile={selectedTile}
          />
        </aside>
        <Transition show={open} as={Fragment}>
          <Dialog
            className="overflow-x-none  w-full"
            onClose={() => {
              setOpen(false)
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className="fixed  inset-0 z-20 bg-slate-400/50"
                aria-hidden="true"
              />
            </Transition.Child>

            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="ease-in duration-200"
              leaveFrom="translate-y-0"
              leaveTo="translate-y-full"
              as={Fragment}
            >
              <div className="fixed bottom-0  left-0 z-20 h-2/3 w-full overflow-y-auto  bg-lightGray shadow">
                <Dialog.Panel className=" flex  h-full w-full  flex-col p-4 ">
                  <div className="mt-2 flex w-full items-center justify-between ">
                    {" "}
                    <Dialog.Title
                      as="h2"
                      className="text-xl font-semibold text-primary-600"
                    >
                      Filters
                    </Dialog.Title>{" "}
                    <button
                      className=" p-2 text-primary-600"
                      onClick={() => {
                        setOpen(false)
                      }}
                    >
                      <AiFillCloseCircle size={32} />
                    </button>
                  </div>
                  <Filter
                    allString={miscString.nodes[0].all}
                    filters={filters}
                    activeTile={activeTile}
                    subTile={subTile}
                    handleActiveTile={handleActiveTile}
                    handleSubTile={handleSubTile}
                    selectedTile={selectedTile}
                  />
                </Dialog.Panel>
              </div>
            </Transition.Child>
          </Dialog>
        </Transition>
        <div className="w-full">
          <div className="flex w-full flex-col  items-start justify-start gap-4">
            <h1 className="pb-2 font-bold capitalize leading-tight text-primary-600 ">
              {getHeading()}
            </h1>
            <div className="z-10 mb-4 flex w-full flex-col justify-between gap-4 sm:flex-row">
              <ComboSearch
                placeholder={electronicPage.singleString[1].text}
                elements={allElectronics.nodes}
                onChange={setSearchResult}
                displayValue={displayValue}
                selectedOption={searchResult}
              />
              <button
                className="flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border  border-gray-300  py-5 px-4 text-left md:hidden    md:w-fit"
                onClick={() => {
                  setOpen(true)
                  setSearchResult(null)
                }}
              >
                Categories <BiFilterAlt />
              </button>
            </div>
          </div>
          {searchResult ? (
            <ElectronicCard key={searchResult.id} electronic={searchResult} />
          ) : (
            <div>{getCollections()}</div>
          )}
        </div>
      </div>
    </Layout>
  )
}
export default ElectronicsRoot

export const query = graphql`
  query ElectronicsRootQuery($locale: String!) {
    electronicPage: datoCmsElectronicPage(locale: { eq: $locale }) {
      headerTitle
      locale
      singleString {
        text
      }
      seo {
        seoTitle: title
        seoDescription: description
        seoImage: image {
          seoImageUrl: url
        }
      }
    }

    allElectronics: allDatoCmsElectronic(
      filter: { locale: { eq: $locale } }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        locale
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
            title
            value
          }
          title
          url
          value
        }
        category {
          title
          url
        }
      }
    }

    allBoards: allDatoCmsElectronic(
      filter: {
        categoryElectronic: {
          treeParent: { value: { eq: "boards" }, locale: { eq: $locale } }
        }
      }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
            title
            value
          }
          title
          url
          value
        }
        category {
          title
          url
        }
      }
    }
    miscString: allDatoCmsMiscString(filter: { locale: { eq: $locale } }) {
      nodes {
        all
      }
    }
    allMotherboard: allDatoCmsElectronic(
      filter: {
        categoryElectronic: {
          value: { eq: "motherboards" }
          locale: { eq: $locale }
        }
      }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
          }
          title
          url
          value
        }
        category {
          title
          url
        }
      }
    }
    allWiring: allDatoCmsElectronic(
      filter: {
        categoryElectronic: { value: { eq: "wiring" }, locale: { eq: $locale } }
      }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
          }
          title
          url
          value
        }
        category {
          title
          url
        }
      }
    }
    allAccessory: allDatoCmsElectronic(
      filter: {
        categoryElectronic: {
          value: { eq: "accessory boards" }
          locale: { eq: $locale }
        }
      }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
          }
          title
          url
        }
        category {
          title
          url
        }
      }
    }
    allAccess: allDatoCmsElectronic(
      filter: {
        categoryElectronic: { value: { eq: "access" }, locale: { eq: $locale } }
      }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
          }
          title
          url
          value
        }
        category {
          title
          url
        }
      }
    }
    allDisplay: allDatoCmsElectronic(
      filter: {
        categoryElectronic: {
          treeParent: { value: { eq: "display" }, locale: { eq: $locale } }
        }
      }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
            value
            title
          }
          title
          url
        }
        category {
          title
          url
        }
      }
    }
    allLCD: allDatoCmsElectronic(
      filter: {
        categoryElectronic: { value: { eq: "lcd" }, locale: { eq: $locale } }
      }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
          }
          title
          url
        }
        category {
          title
          url
        }
      }
    }
    allTFT: allDatoCmsElectronic(
      filter: {
        categoryElectronic: { value: { eq: "tft" }, locale: { eq: $locale } }
      }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
          }
          title
          url
        }
        category {
          title
          url
        }
      }
    }
    allPulsantiere: allDatoCmsElectronic(
      filter: {
        categoryElectronic: {
          value: { eq: "operating panels" }
          locale: { eq: $locale }
        }
      }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
          }
          title
          url
        }
        category {
          title
          url
        }
      }
    }
    allPower: allDatoCmsElectronic(
      filter: {
        categoryElectronic: {
          value: { eq: "power boards" }
          locale: { eq: $locale }
        }
      }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
          }
          title
          url
        }
        category {
          title
          url
        }
      }
    }
    allApps: allDatoCmsElectronic(
      filter: {
        categoryElectronic: {
          value: { eq: "app & software" }
          locale: { eq: $locale }
        }
      }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
          }
          title
          url
        }
        category {
          title
          url
        }
      }
    }
    allSafetyDevice: allDatoCmsElectronic(
      filter: {
        categoryElectronic: {
          value: { eq: "safety device" }
          locale: { eq: $locale }
        }
      }
      sort: { fields: position }
    ) {
      nodes {
        id: originalId
        title
        slug
        tag
        hero {
          gatsbyImageData(height: 190)
        }
        categoryElectronic {
          treeParent {
            url
          }
          title
          url
        }
        category {
          title
          url
        }
      }
    }
    filters: allDatoCmsElectronicsCategory(
      sort: { fields: treeParent___treeChildren___position, order: DESC }
      filter: { locale: { eq: $locale } }
    ) {
      nodes {
        title
        value
        treeChildren {
          title
          value
          treeParent {
            title
            value
            treeChildren {
              title
              value
            }
          }
        }
        treeParent {
          title
          value
        }
      }
    }
  }
`
