import { Combobox, Transition } from "@headlessui/react"
import React, { Fragment, useState } from "react"
import { BiSearch } from "react-icons/bi"
import { BsCheck, BsChevronDown } from "react-icons/bs"
const ComboSearch = ({
  selectedOption,
  onChange,
  placeholder,
  elements,
  displayValue,
}) => {
  const [query, setQuery] = useState("")
  const filteredElements =
    query === ""
      ? elements
      : elements.filter(element =>
          element.title
            ? element.title
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(query.toLowerCase().replace(/\s+/g, ""))
            : element.name
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(query.toLowerCase().replace(/\s+/g, ""))
        )
  const getDisplayValue = element => {
    if (displayValue) {
      if (element?.title) {
        return element.title
      } else {
        return element?.name
      }
    } else {
      return ""
    }
  }
  return (
    <Combobox value={selectedOption} onChange={onChange} nullable>
      <div className="relative z-20 mt-1 w-full">
        <div className="relative w-full cursor-default overflow-hidden rounded-lg">
          <BiSearch
            size={24}
            className="absolute left-2 top-0 bottom-0 h-full text-gray-900"
          />
          <Combobox.Input
            autoComplete="off"
            className="block w-full rounded-lg border border-gray-300 bg-white py-5 px-4 pl-10 text-lg font-medium text-gray-900 focus:border-blue-500 focus:ring-blue-500 "
            displayValue={element => getDisplayValue(element)}
            onChange={event => setQuery(event.target.value)}
            placeholder={placeholder}
          />
          <Combobox.Button className="absolute inset-y-0 right-2 flex items-center pr-2">
            <BsChevronDown
              size={24}
              className=" text-gray-900"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredElements.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filteredElements.map(element => (
                <Combobox.Option
                  key={element.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active
                        ? "text-semibold bg-primary-600 text-white"
                        : "text-gray-900"
                    }`
                  }
                  value={element}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate text-lg ${
                          selected ? " font-medium" : "font-normal"
                        }`}
                      >
                        {element.title ? element.title : element.name}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? "text-white" : "text-primary-600"
                          }`}
                        >
                          <BsCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  )
}

export default ComboSearch
