import { Disclosure, Transition } from "@headlessui/react"
import React from "react"
import { FiChevronDown } from "react-icons/fi"
const Filter = ({
  filters,
  activeTile,
  subTile,
  handleActiveTile,
  allString,
  handleSubTile,
}) => {
  return (
    <div className="mt-4 flex w-full flex-col items-center justify-center ">
      <div className="flex w-full flex-col items-start justify-start gap-4 overflow-x-auto py-4  px-1 text-left lg:items-start lg:justify-start">
        <button
          className={`${
            activeTile === allString ? "font-bold" : "font-normal "
          } cursor-pointer text-lg   transition-all`}
          onClick={() => {
            handleActiveTile(allString)
          }}
        >
          {allString}
        </button>
        <div className="flex w-full flex-col">
          {filters.nodes.map((filter, i) =>
            filter.treeChildren.length > 0 ? (
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={`${
                        activeTile === filter
                          ? "font-bold text-primary-600"
                          : "font-normal "
                      } flex w-full cursor-pointer items-center gap-2  py-2 text-left text-lg transition-all  `}
                      onClick={() => {
                        handleActiveTile(filter)
                      }}
                    >
                      {filter.title}
                      <FiChevronDown
                        size={22}
                        className={`transition-all  ${
                          open ? "rotate-180 transform" : ""
                        }`}
                      />
                    </Disclosure.Button>
                    <Transition
                      enter="transition duration-200 ease-out"
                      enterFrom=" opacity-0 "
                      enterTo=" opacity-100 "
                      leave="transition duration-200 ease-out"
                      leaveFrom="  opacity-100"
                      leaveTo=" opacity-0"
                    >
                      <Disclosure.Panel>
                        {filter?.treeChildren && (
                          <div className="flex w-full flex-col items-start justify-start gap-4 overflow-x-auto  p-2 lg:items-start lg:justify-start">
                            {filter?.treeChildren?.map((tree, i) => (
                              <button
                                button
                                className={`${
                                  subTile === tree
                                    ? "font-bold text-primary-600"
                                    : "font-normal "
                                } cursor-pointer  text-left text-lg  transition-all`}
                                onClick={() => {
                                  handleSubTile(tree)
                                }}
                              >
                                {tree.title}
                              </button>
                            ))}
                          </div>
                        )}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ) : null
          )}
        </div>
        {filters.nodes.map((filter, i) =>
          filter.treeChildren.length < 1 &&
          filter.treeParent?.value === undefined ? (
            <button
              className={`${
                activeTile === filter ? "font-bold" : "font-normal "
              } cursor-pointer text-left text-lg  transition-all`}
              onClick={() => {
                handleActiveTile(filter)
              }}
            >
              {filter.title}
            </button>
          ) : null
        )}
      </div>
    </div>
  )
}

export default Filter
