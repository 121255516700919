import React from "react"
import ElectronicCard from "./ElectronicCard"

const ElectronicsCollection = ({ collection, resultString }) => {
  return (
    <div className="my-4 flex w-full flex-col space-y-4">
      <span className="font text-lg font-semibold">
        {collection?.nodes.length} {resultString}
      </span>

      <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2">
        {collection?.nodes.map(electronic => (
          <ElectronicCard key={electronic} electronic={electronic} />
        ))}
      </div>
    </div>
  )
}

export default ElectronicsCollection
